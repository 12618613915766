import { Component, OnInit } from '@angular/core';
import { TempoService } from '../services';
import { Options } from 'ng5-slider';

// import '@webcomponents/custom-elements/src/native-shim';
// import '@webcomponents/custom-elements/custom-elements.min';

@Component({
  selector: 'app-tempo',
  templateUrl: './tempo.component.html',
  styleUrls: ['./tempo.component.css']
})
export class TempoComponent implements OnInit {
  tempoBeatsDetail: any;

  value: number = 60;
  options: Options = {
    ceil: 150,
    floor: 60,
    showSelectionBar: true,
    showTicks: true,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 10,
    step: 10,
    getTickColor: (value: number): string => {
      if (value < 90) {
        return 'red';
      }
      if (value < 120) {
        return 'orange';
      }
      if (value < 150) {
        return 'yellow';
      }
      return '#2AE02A';
    }
  };

  constructor(private tempoService: TempoService) {}

  ngOnInit() {
    this.getTempoBeats();
  }

  getTempoBeats() {
    this.tempoService.findByTempoBPM(this.value)
      .subscribe(resp => {
        this.tempoBeatsDetail = resp['result'];
      });
  }
}
