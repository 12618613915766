import { Component, OnInit } from '@angular/core';
import {NewOrderService} from '../services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  searchEmail: any = null;
  pageNumber: number = 1;
  rowsPerPage: number = 10;
  pageSize: number = 0;
  orderHistory: any = {};
  config: any;

  constructor(private newOrderService: NewOrderService, private route: Router) { }

  ngOnInit() {
    this.getAllCompleteOrders();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.pageNumber = this.config.currentPage;
    this.getAllCompleteOrders();
  }

  getAllCompleteOrders() {
    if (this.searchEmail === '') {
      this.searchEmail = null;
    }

    this.newOrderService.getOrderHistory(this.searchEmail, this.rowsPerPage, this.pageNumber - 1)
      .subscribe(resp => {
        this.orderHistory = resp['result']['pagedData'];
        this.pageSize = resp['result']['totalPages'] * this.rowsPerPage;
        this.config = {
          itemsPerPage: this.rowsPerPage,
          currentPage: this.pageNumber,
          totalItems: this.pageSize
        };
      });
  }

}
