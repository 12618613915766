import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  cartDetailAll: any;
  constructor() { }

  ngOnInit() {
    this.getLocalStorageDetail();
  }

  getLocalStorageDetail() {
   this.cartDetailAll = JSON.parse(localStorage.getItem('beastByYouCartItem'));
   console.log(this.cartDetailAll);
  }
}
