import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {CommunityComponent} from './community/community.component';
import {AboutusComponent} from './aboutus/aboutus.component';
import {TempoComponent} from './tempo/tempo.component';
import {OrderHistoryComponent} from './order-history/order-history.component';
import {CartComponent} from './cart/cart.component';
import {NewOrderComponent} from './new-order/new-order.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'community', component: CommunityComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'tempo', component: TempoComponent},
  {path: 'orderhistory', component: OrderHistoryComponent},
  {path: 'cart', component: CartComponent},
  {path: 'neworder', component: NewOrderComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
