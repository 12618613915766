import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewOrderService {

  constructor(private http: HttpClient) { }

  getAllGenre() {
    return this.http.get(`${environment.apiUrl}types/getAllGenres`);
  }

  getAllInstrument() {
    return this.http.get(`${environment.apiUrl}types/getAllInstruments`);
  }

  getAllMood() {
    return this.http.get(`${environment.apiUrl}types/getAllMoods`);
  }

  getPriceRange(time) {
    return this.http.get(`${environment.apiUrl}beatPrice/getPriceByTimeRange/${time}`);
  }

  createOrder(order) {
    return this.http.post(`${environment.apiUrl}order/save`, order);
  }

  getOrderHistory(email, rowsPerPage, pageNumber) {
    return this.http.get(`${environment.apiUrl}order/findCompleteOrders/${email}/${pageNumber}/${rowsPerPage}`);
  }
}
