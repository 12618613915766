import {Component, OnInit, ɵɵnamespaceMathML} from '@angular/core';
import {Options} from 'ng5-slider';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {MathHelper} from 'ng5-slider/math-helper';
import {NewOrderService} from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import swal, {default as Swal} from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {

  genreValue: any;
  genre: any = [];
  instrumentValue: any;
  instrument: any = [];
  mood: any = [];
  orderDetail: any = [];
  orderDetailCart: any = [];
  sliderFloor: number;
  genreDetail: any;
  instrumentDetail: any;
  moodDetail: any;
  beatPrice: any;
  moodType: any;
  youTubeLink: any;
  uploadSampleFile: any;
  uploadSampleFileBase64: any;
  sampleFileName: any = 'Choose file';
  link1: any;
  link2: any;
  link3: any;
  fullName: any;
  email: any;
  mobileNo: any;
  postalCode: any;
  address: any;
  htmlContentDescription: any = '';
  cardNumber: any;
  expMonth: any;
  expYear: any;
  cvc: any;
  checkFullName: boolean = false;
  checkEmail: boolean = false;
  checkMobile: boolean = false;
  checkPostalCode: boolean = false;
  checkAddress: boolean = false;

  value: number = 60;
  options: Options = {
    ceil: 150,
    floor: 60,
    showSelectionBar: true,
    showTicks: true,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 10,
    // step: 10,
    getTickColor: (value: number): string => {
      if (value < 90) {
        return 'red';
      }
      if (value < 120) {
        return 'orange';
      }
      if (value < 150) {
        return 'yellow';
      }
      return '#2AE02A';
    }
  };


  value2: number = 0;
  options2: Options = {
    ceil: 360,
    floor: 60,
    showSelectionBar: true
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'insertImage',
        'insertVideo',
        'superscript',
        'subscript',
        'strikeThrough'
      ]
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  constructor(private newOrderService: NewOrderService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getAllGenre();
    this.getAllInstrument();
    this.getAllMood();
    this.spinner.hide('sp5');
  }

  selectGenreValue(event: any) {
    this.genreValue = event.target.value;
    this.genreDetail.forEach(value => {
      if (value.id.toString() === this.genreValue) {
        if (this.genreValue !== '') {
          this.genre.push(value);
          event.target.value = '';
          console.log(this.genre);
        }
      }
    });
  }

  closeGenre(value) {
    this.genre.splice(value, 1);
  }

  selectInstrumentValue(event: any) {
    this.instrumentValue = event.target.value;
    this.instrumentDetail.forEach(value => {
      if (value.id.toString() === this.instrumentValue) {
        if (this.instrumentValue !== '') {
          this.instrument.push(value);
          event.target.value = '';
          console.log(this.instrument);
        }
      }
    });
  }

  closeInstrument(value) {
    this.instrument.splice(value, 1);
  }

  valueChangeSlider(valueSlider) {
    const x = this.value2 / 60;
    this.sliderFloor = Math.floor(x);
    this.getBeatPrice();
  }

  getAllGenre() {
    this.newOrderService.getAllGenre()
      .subscribe(resp => {
        this.genreDetail = resp['result'];
      });
  }

  getAllInstrument() {
    this.newOrderService.getAllInstrument()
      .subscribe(resp => {
        this.instrumentDetail = resp['result'];
      });
  }

  getAllMood() {
    this.newOrderService.getAllMood()
      .subscribe(resp => {
        this.moodDetail = resp['result'];
      });
  }

  getBeatPrice() {
    this.newOrderService.getPriceRange(this.value2)
      .subscribe(resp => {
        this.beatPrice = resp['result']['price'];
      });
  }

  getMood(event) {
    this.moodType = event.target.value;
    this.moodDetail.forEach(value => {
      if (value.id.toString() === this.moodType) {
        this.mood = [];
        this.mood.push(value);
        console.log(this.mood);
      }
    });
  }

  getUploadPcFile(event) {
    this.uploadSampleFile = event.target.files[0];
    this.sampleFileName = this.uploadSampleFile.name;

    // const file = this.uploadSampleFile[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    //
    // reader.onload = () => {
    //   this.uploadSampleFileBase64 = reader.result;
    // };
    // reader.onerror = function(error) {
    //   console.log('Error: ', error);
    // };
  }

  chargeCreditCard() {
    if (this.fullName === undefined || this.fullName === '') {
      this.checkFullName = true;
    } else {
      this.checkFullName = false;
    }

    if (this.email === undefined || this.email === '') {
      this.checkEmail = true;
    } else {
      this.checkEmail = false;
    }

    if (this.mobileNo === undefined || this.mobileNo === '') {
      this.checkMobile = true;
    } else {
      this.checkMobile = false;
    }

    if (this.postalCode === undefined || this.postalCode === '') {
      this.checkPostalCode = true;
    } else {
      this.checkPostalCode = false;
    }

    if (this.address === undefined || this.address === '') {
      this.checkAddress = true;
    } else {
      this.checkAddress = false;
    }

    if (!this.checkFullName && !this.checkAddress && !this.checkPostalCode && !this.checkMobile && !this.checkEmail) {
      this.spinner.show('sp5');
      const formdata: any = new FormData();
      formdata.append('moods', JSON.stringify(this.mood));
      formdata.append('genres', JSON.stringify(this.genre));
      formdata.append('instruments', JSON.stringify(this.instrument));
      formdata.append('fullName', this.fullName);
      formdata.append('email', this.email);
      formdata.append('mobile', this.mobileNo);
      formdata.append('postalCode', this.postalCode);
      formdata.append('address', this.address);
      formdata.append('songLength', this.value2);
      formdata.append('description', this.htmlContentDescription);
      formdata.append('sampleYoutubeLink', this.youTubeLink);
      if (this.uploadSampleFile) {
        formdata.append('sampleLocalFile', this.uploadSampleFile, this.sampleFileName);
      } else {
        formdata.append('sampleLocalFile', new Blob(), null);
      }
      formdata.append('referenceTrack1', this.link1);
      formdata.append('referenceTrack2', this.link2);
      formdata.append('referenceTrack3', this.link3);
      formdata.append('tempoValue', this.value);
      formdata.append('price', this.beatPrice);
      formdata.append('charge', this.beatPrice * 100);
      // let form = document.getElementsByTagName("form")[0];
      (<any> window).Stripe.card.createToken({
        number: this.cardNumber,
        exp_month: this.expMonth,
        exp_year: this.expYear,
        cvc: this.cvc
      }, (status: number, response: any) => {
        if (status === 200) {
          const token = response.id;
          formdata.append('token', token);
          this.newOrderService.createOrder(formdata)
            .subscribe(resp => {
              console.log(resp);
              if (resp['code'] === '200') {
                this.spinner.hide('sp5');
                Swal.fire(
                  'Good job!',
                  'order has been created successfully!',
                  'success'
                );
                this.mood = [];
                this.genre = [];
                this.instrument = [];
                this.fullName = '';
                this.email = '';
                this.mobileNo = '';
                this.postalCode = '';
                this.address = '';
                this.value2 = 0;
                this.htmlContentDescription = '';
                this.youTubeLink = '';
                // this.uploadSampleFile[0], this.sampleFileName
                this.link1 = '';
                this.link2 = '';
                this.link3 = '';
                this.value = 60;
                this.beatPrice = '';
                this.cardNumber = '';
                this.expMonth = '';
                this.expYear = '';
                this.cvc = '';
              } else {
                this.spinner.hide('sp5');
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                  footer: 'Please contact admin producers@beatsbyyou.app'
                });
              }
            }, error1 => {
              this.spinner.hide('sp5');
              console.log(error1);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                footer: 'Please contact admin producers@beatsbyyou.app'
              });
            });
        } else {
          this.spinner.hide('sp5');
          console.log(response.error.message);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: 'Please contact admin producers@beatsbyyou.app'
          });
        }
      });
    }
  }

  addCart() {
    this.orderDetail = {
      moods: this.moodType,
      genres: this.genre,
      instruments: this.instrument,
      fullName: this.fullName,
      email: this.email,
      mobile: this.mobileNo,
      postalCode: this.postalCode,
      address: this.address,
      songLength: this.value2,
      description: this.htmlContentDescription,
      sampleYoutubeLink: this.youTubeLink,
      sampleLocalFile: this.uploadSampleFileBase64,
      referenceTrack1: this.link1,
      referenceTrack2: this.link2,
      referenceTrack3: this.link3,
      tempoValue: this.value,
      price: this.beatPrice
    };

    if (JSON.parse(localStorage.getItem('beastByYouCartItem')) !== null) {
      JSON.parse(localStorage.getItem('beastByYouCartItem')).forEach(obj => {
        this.orderDetailCart.push(obj);
      });
    }

    this.orderDetailCart.push(this.orderDetail);

    localStorage.setItem('beastByYouCartItem', JSON.stringify(this.orderDetailCart));
    this.orderDetailCart = [];

    this.router.navigate(['cart']);
  }
}
